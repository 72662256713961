<template>
  <div class="mainPage">
    <h1>Willkommen bei Ihrer IT-Beratung</h1>
    <p>Als IT-Beratungsunternehmen bieten wir umfassende Dienstleistungen für kleine Unternehmen. Lassen Sie uns
      gemeinsam Ihre Herausforderungen in der IT lösen und Ihre Geschäftseffizienz steigern.</p>
    <div class="contact">
      <div><strong>Kontakt:</strong></div>
      <div>E-Mail: <a :href="'mailto:' + email">{{ email }}</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainPage',
  data() {
    return {
      email: 'mail@quest-it.eu',
    };
  }
};
</script>

<style>
h1 {
  text-align: center;
  margin-top: 20px;
}
</style>
