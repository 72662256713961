<template>
  <header>
    <img :src="logoUrl" :style="{ maxWidth: logoWidth, height: logoHeight }" alt="Firmenlogo">
  </header>
</template>

<script>
export default {
  props: {
    logoWidth: {
      type: String,
      default: '150px'
    },
    logoHeight: {
      type: String,
      default: 'auto'
    }
  },
  data() {
    return {
      logoUrl: require('../assets/logo.png')
    }
  }
}
</script>

<style scoped>
header {
  text-align: center;
  margin-bottom: 20px;
}
</style>
