<template>
    <footer>
        <div class="links">
            <router-link to="/">Home</router-link>
            <router-link to="/impressum">Impressum</router-link>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent',
}
</script>

<style scoped>
footer {
    background-color: #333;
    color: #f4f4f9;
    padding: 20px 0;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
}

.links {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.links a {
    color: #FFA500;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
}

.links a:hover {
    text-decoration: underline;
}
</style>