<template>
  <div class="header">
    <HeaderComponent :logoWidth="'300px'" :logoHeight="'auto'" />
  </div>
  <div id="app content-container">
    <router-view></router-view>
  </div>
  <div class="footer">
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';

export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent
  }
};
</script>

<style>
/* Any global styles can be added here */
</style>
