<template>
  <div class="impressum">
    <h1>Impressum</h1>
    <div class="contact">
      <p>
        <strong>Unternehmensname:</strong> {{ company }}<br>
        <strong>E-Mail:</strong> <a :href="'mailto:' + email">{{ email }}</a><br>
        <strong>Geschäftsführer:</strong> {{ ceo }}<br>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Impressum-page',
  data() {
    return {
      email: 'mail@quest-it.eu',
      company: 'Quest-IT',
      ceo: 'Jan Quest',
    };
  }
};
</script>

<style>
h1 {
  color: #333;
  margin-bottom: 20px;
}

p {
  color: #666;
  line-height: 1.6;
}

a {
  color: #FFA500;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}
</style>
